/* @src/components/Card.module.css */

.textCard {
  @apply bg-gray-800 shadow-xl hover:shadow-none cursor-pointer w-auto rounded-3xl flex flex-col items-center justify-center m-4;
}

.textCardAnimation {
  @apply transition-all duration-500 ease-in-out;
}


.textCardTitleWrapper {
  @apply pt-4 pb-4 w-full px-4;
}

.textCardTitle {
  @apply font-medium leading-5 text-base tracking-wider text-gray-400;
}

.textCardDisplay {
  @apply col-span-full px-4 py-2 mx-4 my-2 rounded-3xl items-center justify-center;
}

.textCardDisplayText {
  @apply font-medium text-base tracking-wider text-gray-400;
}