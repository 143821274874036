/* @src/components/Card.module.css */

.dataCard {
  @apply bg-gray-800 shadow-xl hover:shadow-none cursor-pointer rounded-3xl flex flex-col items-center justify-center m-2 col-span-full;
}

.flagCard {
  @apply bg-gray-800 shadow-xl hover:shadow-none cursor-pointer col-span-1 rounded-3xl flex items-center justify-center m-4 p-6 overflow-hidden;
}

.dataCardAnimation {
  @apply transition-all duration-500 ease-in-out;
}

.dataCardHeader {
  @apply relative mt-2 mb-4 mx-2 px-2 h-32 w-full flex flex-row justify-center;
}

.dataCardImage {
  @apply object-cover w-full h-full relative rounded-2xl overflow-hidden;
}

.dataCardValueWrapper {
  @apply pt-10 pb-6 px-4 z-10 absolute;
}
.dataCardValue {
  @apply font-semibold tracking-wider text-4xl text-white;
}

.dataCardTitleWrapper {
  @apply pt-4 pb-4 w-full px-4;
}

.dataCardTitle {
  @apply font-medium leading-none text-base tracking-wider text-gray-400;
}

.dataCardBadgeContainer {
  @apply absolute bottom-0 left-0 -mb-4 flex flex-row z-20 overflow-hidden;
}

.mcdangerBadge {
  @apply h-10 w-10 flex items-center justify-center text-xl bg-white hover:bg-red-500 text-red-500 hover:text-white rounded-2xl shadow-xl;
}

.dataCardBadge {
  @apply h-10 w-max ml-2 px-2 bg-white hover:bg-blue-600 flex items-center justify-center font-medium text-blue-600 hover:text-white rounded-2xl shadow-xl float-left;
}
.dataCardYearBadge {
  @apply h-10 w-max ml-2 px-2 bg-white hover:bg-blue-600 flex items-center justify-center font-medium text-blue-600 hover:text-white rounded-2xl shadow-xl float-right;
}

.mccounter {
  @apply text-gray-800 ml-2;
}

.dataCardBadgeAnimation {
  @apply transform-gpu translate-y-0 hover:-translate-y-1 transition-all duration-300 ease-in-out;
}