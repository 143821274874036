/* @src/components/Card.module.css */

.gridCard {
  @apply bg-gray-800 shadow-xl hover:shadow-none cursor-pointer rounded-3xl flex flex-col items-center justify-center m-2 col-span-full;
}

.gridCardAnimation {
  @apply transition-all duration-500 ease-in-out;
}

.gridCardHeader {
  @apply relative mt-4 mx-2 px-2 justify-center;
}

.gridCardTitleWrapper {
  @apply pt-4 pb-4 w-full px-4;
}

.gridCardTitle {
  @apply font-medium leading-5 text-base tracking-wider text-gray-400;
}

.gridCardItem {
  @apply px-4 py-2 mx-2 my-2 rounded-3xl shadow-xl hover:shadow-none cursor-pointer bg-gray-700;
}
.gridFullCardItem {
  @apply px-4 py-2 mx-4 my-2 rounded-3xl shadow-xl hover:shadow-none cursor-pointer bg-gray-700;
}

.gridCardItemTitle {
  @apply font-medium text-base tracking-wider text-gray-400;
}