/* @src/components/Card.module.css */

.donutCard {
  @apply bg-gray-800 shadow-xl hover:shadow-none cursor-pointer rounded-3xl flex flex-col items-center justify-center p-2 h-full;
}

.donutCardAnimation {
  @apply transition-all duration-500 ease-in-out;
}

.donutCardTitleWrapper {
  @apply pb-4 w-full px-4;
}

.donutCardTitle {
  @apply font-medium leading-none text-base tracking-wider text-gray-400;
}

.donutCardChartFrame {
  @apply p-4 mx-4 relative w-full;
}
.donutCardChart {
  @apply top-0 relative z-20;
}

.donutCardItemFrame {
  @apply absolute transform z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 justify-center items-center px-20;
}
.donutCardItemValue {
  @apply font-bold text-center text-6xl tracking-wider text-gray-400 max-w-full overflow-hidden;
}
.donutCardItemTitle {
  @apply font-medium text-center text-xl tracking-wider text-gray-400 max-w-full break-words;
}

.donutCardBadgeContainer {
  @apply absolute bottom-0 left-0 -mb-4 flex flex-row z-20 overflow-hidden;
}

.mcdangerBadge {
  @apply h-10 w-10 flex items-center justify-center text-xl bg-white hover:bg-red-500 text-red-500 hover:text-white rounded-2xl shadow-xl;
}

.donutCardBadge {
  @apply h-10 w-max ml-2 px-2 bg-white hover:bg-blue-600 flex items-center justify-center font-medium text-blue-600 hover:text-white rounded-2xl shadow-xl float-left;
}
.donutCardYearBadge {
  @apply h-10 w-max ml-2 px-2 bg-white hover:bg-blue-600 flex items-center justify-center font-medium text-blue-600 hover:text-white rounded-2xl shadow-xl float-right;
}

.mccounter {
  @apply text-gray-800 ml-2;
}

.donutCardBadgeAnimation {
  @apply transform-gpu translate-y-0 hover:-translate-y-1 transition-all duration-300 ease-in-out;
}