.App {
  width: 100vw;
  height: 100vh;
  position: relative;
}

Atlas {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.UI-Container {
  z-index: 10;
  width: 55%;
  height: 95%;
  /* background: rgba(119, 119, 119, 0.219); */
  position: absolute;
  right: 2%;
  top: 0;
  -webkit-transform: translate(-50%, 2.5%);
  transform: translate(0, 2.5%);
}

.UI-MenuBtn {
  z-index: 5;
  position: absolute;
  left: 50%;
  bottom: 5%;
  -webkit-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
}

.text-gradient {
  background: linear-gradient(to right, #30CFD0, #c43ad6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}